<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';
import useVuelidate from "@vuelidate/core";
import {
    required,
    helpers
} from "@vuelidate/validators";

export default {
    page: {
        title: "Services",
        meta: [
            {
                name: "description",
                content: 'description',
            },
        ],
    },

    data() {
        return {
            url: process.env.VUE_APP_URL,
            v$: useVuelidate(),
            isshow: false,
            isShowData: false,
            submitted: false,
            title: "Services",
            searchValueTable: "",
            headers: [
                { text: "Title", value: "title" },
                { text: "Price", value: "amount" },
                { text: "Type", value: "type" },
                { text: "Action", value: "editData" }
            ],
            tableItems: [],
            tableData: [],
            editData: '',
            service: {
                title: '',
                amount: '',
                type: '',
                addOnType: '',
                storyLimit: 0,
                journalLimit: 0,
            },
            modalTaskTotalAdd: false,
            isEditService: false

        }
    },
    components: {
        Layout,
        PageHeader,
        EasyDataTable,
    },
    validations: {
        service: {
            title: {
                required: helpers.withMessage("Title is required", required),
            },
            amount: {
                required: helpers.withMessage("Amount is required", required),
            },
            type: {
                required: helpers.withMessage("Type is required", required)
            },
            storyLimit: {
                required: helpers.withMessage("Story Limit is required", required)
            },
            journalLimit: {
                required: helpers.withMessage("Journal Limit is required", required)
            }
        }
    },
    mounted() {
        this.getPackages()
    },
    activated() {
        this.getPackages()
    },
    methods: {
        getPackages() {
            axios.get(this.url + "api/getPackages").then((res) => {
                this.tableItems = res.data.filter(item => !item.type.includes('super'));
            });
        },
        editService(id) {
            this.modalTaskTotalAdd = true;
            this.isEditService = true;
            // this.service = this.tableItems.find(item => item.id == id);
            axios.post(this.url + "api/editService", { id: id }).then(res => {
                this.service = res.data;
            })
        },
        addService() {
            this.submitted = true;
            this.v$.$touch()
            if (this.v$.$invalid) {
                return false;
            } else {
                axios.post(this.url + "api/addService", this.service).then(() => {
                    this.modalTaskTotalAdd = false;
                    this.$store.state.isAccepted = true
                    this.$store.state.notificationData = 'Service Created Successfully'
                    this.getPackages()
                    setTimeout(() => {
                        this.$store.state.isAccepted = false
                        this.$store.state.notificationData = null
                    }, 2500)
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        updateService() {
            this.submitted = true;
            this.v$.$touch()
            if (this.v$.$invalid) {
                return false;
            } else {
                axios.post(this.url + "api/updateService", this.service).then(() => {
                    this.modalTaskTotalAdd = false;
                    this.$store.state.isAccepted = true
                    this.$store.state.notificationData = 'Service Updated Successfully'
                    this.getPackages()
                    setTimeout(() => {
                        this.$store.state.isAccepted = false
                        this.$store.state.notificationData = null
                    }, 2500)
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        resetFields(){
            this.service = {
                title: '',
                amount: '',
                type: '',
                storyLimit: 0,
                journalLimit: 0,  
            };
            this.submitted = false;
            this.isEditService = false;
        }
    }
};
</script>
<template>
    <layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader :title="title" />
            </div>
            <div class="col-auto">
                <!-- <a href="javascript:void(0)" class="btn btn-theme"><i class="bx bx-user-plus font-size-18 align-middle me-2"></i>Add Client</a> -->
                <button type="button" @click="modalTaskTotalAdd = true,resetFields()" class="btn btn-theme">
                    <i class="bx bx-user-plus font-size-18 align-middle me-2"></i>
                    Add Service
                </button>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="main-search-card ">

                    <!-- <Transition name="fade">
            <div class="main-group-search p-3 border mb-3" v-if="isshow">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <label class="form-label">Client Name</label>
                  <div class="single-select2-cstm">
                    <Select2 v-model="clientName" placeholder="Select Client Name" :options="clientNameOptions"
                      :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)"
                      @select="mySelectEvent('name')" />
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="form-label">Story Title</label>
                  <div class="single-select2-cstm">
                    <Select2 v-model="storyTitle" placeholder="Select Story Title" :options="storyTitleOptions"
                      :settings="{ multiple: false }" @change="myChangeEvent($event)"
                      @select="mySelectEvent('storyTitle')" />
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label class="form-label">Client Email</label>
                  <div class="single-select2-cstm">
                    <Select2 v-model="clientEmail" placeholder="Select Client Email" :options="clientEmailOptions"
                      :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)"
                      @select="mySelectEvent('email')" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="row mt-3 gx-2 justify-content-end">
                    <div class="col-auto">
                      <button class="btn btn-light" @click="getClients()">Reset</button>
                    </div>
                    <div class="col-auto">
                      <button class="btn btn-theme" @click="filterData()">Apply</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition> -->


                    <b-modal v-model="modalTaskTotalAdd" id="modalTaskTotalAdd" centered scrollable size="md"
                        :title="isEditService == true ? 'Update Service' : 'Add Service'" title-class="font-18"
                        hide-footer>
                        <form>
                            <div class="mainFormModal">
                                <div class="mb-3">
                                    <label class="form-label">Title</label>
                                    <div class="single-select2-cstm">
                                        <input id="task-title-input" :disabled="isEditService" v-model="service.title" type="text"
                                            class="form-control" placeholder="Type Title" :class="{
                    'is-invalid': submitted && v$.service.title.$error,
                }">
                                        <div v-if="submitted && v$.service.title.$error" class="invalid-feedback">
                                            <span v-if="v$.service.title.required.$message">
                                                {{ v$.service.title.required.$message }}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="mb-3">
                                    <label for="task-title-input">Type</label>
                                    <select :disabled="isEditService" v-model="service.type" class="form-control" placeholder="Select Type"
                                        :class="{
                    'is-invalid': submitted && v$.service.type.$error,
                }">
                                        <option value="submission">Submission</option>
                                        <option value="flash">Flash Fiction</option>
                                        <option value="editing">Editing & Consulting</option>
                                        <option value="addOn">Add On</option>
                                    </select>
                                    <div v-if="submitted && v$.service.type.$error" class="invalid-feedback">
                                        <span v-if="v$.service.type.required.$message">
                                            {{ v$.service.type.required.$message }}
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="task-title-input">Price</label>
                                    <input id="task-title-input" v-model="service.amount" type="number"
                                        class="form-control" placeholder="Type Price" :class="{
                    'is-invalid': submitted && v$.service.amount.$error,
                }">
                                    <div v-if="submitted && v$.service.amount.$error" class="invalid-feedback">
                                        <span v-if="v$.service.amount.required.$message">
                                            {{ v$.service.amount.required.$message }}
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3" v-if="(service.type.includes('addOn'))">
                                    <label for="task-title-input">${{service.amount}}/ words</label>
                                    <input id="task-title-input" v-model="service.addOnType" type="text"
                                        class="form-control" placeholder="Type" >
                                </div>
                                <div class="mb-3" v-if="!(service.type.includes('addOn') || service.type.includes('editing'))">
                                    <label for="task-title-input">Story Limit</label>
                                    <input id="task-title-input" v-model="service.storyLimit" type="number"
                                        class="form-control" placeholder="Type Price" :class="{
                    'is-invalid': submitted && v$.service.storyLimit.$error,
                }">
                                    <div v-if="submitted && v$.service.storyLimit.$error" class="invalid-feedback">
                                        <span v-if="v$.service.storyLimit.required.$message">
                                            {{ v$.service.storyLimit.required.$message }}
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3" v-if="!(service.type.includes('editing'))">
                                    <label for="task-title-input">Journal Limit</label>
                                    <input id="task-title-input" v-model="service.journalLimit" type="number"
                                        class="form-control" placeholder="Type Price" :class="{
                    'is-invalid': submitted && v$.service.journalLimit.$error,
                }">
                                    <div v-if="submitted && v$.service.journalLimit.$error" class="invalid-feedback">
                                        <span v-if="v$.service.journalLimit.required.$message">
                                            {{ v$.service.journalLimit.required.$message }}
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end border-top pt-3" v-if="isEditService">
                                    <button type="submit" @click.prevent="updateService()"
                                        class="btn btn-theme">Submit</button>
                                </div>
                                <div class="d-flex justify-content-end border-top pt-3" v-else>
                                    <button type="submit" @click.prevent="addService()"
                                        class="btn btn-theme">Submit</button>
                                </div>
                            </div>
                        </form>
                    </b-modal>

                    <EasyDataTable :headers="headers" :items="tableItems" :search-value="searchValueTable" border-cell
                        theme-color="#3aafa9" :rows-per-page="25" buttons-pagination
                        table-class-name="table-custom-style">
                        <template #item-editData="{ id }">
                            <a @click="editService(id)" class="btn btn-sm btn-soft-info"><i
                                    class="mdi mdi-pencil-outline"></i></a>
                        </template>
                    </EasyDataTable>
                </div>
            </div>
        </div>
    </layout>
</template>
<style scoped>
.fade-enter-active {
    transition: all 0.3s ease-out;
}

.fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>